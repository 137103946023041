import * as React from 'react';
import * as styles from './welcome-section.module.scss';
import loadable from '@loadable/component';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { Button } from '../../../../components/button/button';
import { StaticImage } from 'gatsby-plugin-image';
import FacebookIcon from '@material-ui/icons/Facebook';
import { useSiteMetadata } from '../../../../hooks/useSiteMetadata';
import { useState } from 'react';
const ActionSheetContacts = loadable(
  () => import('../action-sheet-contacts/action-sheet-contacts')
);

export const WelcomeSection = () => {
  const intl = useIntl();
  const { social } = useSiteMetadata();
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const handleActionSheetToggle = () => {
    setIsActionSheetOpen(!isActionSheetOpen);
  };

  return (
    <section className="container">
      <div
        className={`${styles.wrapper} d-flex justify-content-between align-items-center`}
      >
        <div>
          <h1 className={styles.heading}>
            <FormattedMessage id="home.welcome_section.heading_main" />
          </h1>
          <p className={styles.slogan}>
            <FormattedMessage id="home.welcome_section.slogan" />
          </p>
          <Button className={styles.button} onClick={handleActionSheetToggle}>
            <FormattedMessage id="home.welcome_section.cta" />
          </Button>
          <ActionSheetContacts
            isOpen={isActionSheetOpen}
            onClose={handleActionSheetToggle}
            onOpen={handleActionSheetToggle}
          />
          <div className={styles.social}>
            <a
              href={social.facebook}
              rel="noopener"
              target="_blank"
              aria-label={intl.formatMessage({
                id: 'home.welcome_section.facebook_link_alt',
              })}
            >
              <FacebookIcon className={styles.icon} />
            </a>
          </div>
        </div>
        <StaticImage
          className="d-none d-md-block"
          src="../../../../images/cardboard-boxes.png"
          placeholder="tracedSVG"
          loading="eager"
          width={450}
          quality={95}
          formats={['auto', 'webp', 'avif']}
          alt={intl.formatMessage({ id: 'home.welcome_section.image_alt' })}
        />
      </div>
    </section>
  );
};
