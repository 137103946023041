import React, { useState } from 'react';
import * as styles from './stay-in-touch.module.scss';
import loadable from '@loadable/component';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { Wrapper } from '../../../../components/wrapper/wrapper';
import { HighlightedHeadline } from '../../../../components/highlighted-headline/highlighted-headline';
import { Button } from '../../../../components/button/button';
const ActionSheetContacts = loadable(
  () => import('../action-sheet-contacts/action-sheet-contacts')
);

export const StayInTouch = () => {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const handleActionSheetToggle = () => {
    setIsActionSheetOpen(!isActionSheetOpen);
  };

  return (
    <Wrapper className={styles.wrapper}>
      <div className="container">
        <HighlightedHeadline translation="home.stay_in_touch.headline" />
        <p className={styles.description}>
          <FormattedMessage id="home.stay_in_touch.description" />
        </p>
        <div className="d-flex justify-content-center">
          <Button onClick={handleActionSheetToggle}>
            <FormattedMessage id="home.stay_in_touch.button" />
          </Button>
          <ActionSheetContacts
            isOpen={isActionSheetOpen}
            onClose={handleActionSheetToggle}
            onOpen={handleActionSheetToggle}
          />
        </div>
      </div>
    </Wrapper>
  );
};
