import * as React from 'react';
import * as styles from './wrapper.module.scss';

interface IProps {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
}
export const Wrapper = ({ children, className = '' }: IProps) => (
  <section className={`${styles.wrapper} ${className}`}>{children}</section>
);
