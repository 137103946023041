import * as React from 'react';
import * as styles from './highlights.module.scss';
import { Wrapper } from '../../../../components/wrapper/wrapper';
import { HighlightedHeadline } from '../../../../components/highlighted-headline/highlighted-headline';
import { IconType } from '../../../../components/icon/icon';
import {
  IconCard,
  IconCardItem,
} from '../../../../components/icon-card/icon-card';

export const Highlights = () => {
  const highlights: IconCardItem[] = [
    {
      icon: IconType.BULB,
      headline: 'home.highlights.creativity.headline',
      description: 'home.highlights.creativity.description',
    },
    {
      icon: IconType.SHIELD,
      headline: 'home.highlights.reliability.headline',
      description: 'home.highlights.reliability.description',
    },
    {
      icon: IconType.FLEXIBILITY,
      headline: 'home.highlights.flexibility.headline',
      description: 'home.highlights.flexibility.description',
    },
  ];

  return (
    <Wrapper className={styles.highlights}>
      <div className="container">
        <HighlightedHeadline translation="home.highlights.headline" />
        <div className="row">
          {highlights.map((highlight, index) => (
            <IconCard
              key={index}
              data={highlight}
              className="col-12 col-md-4"
            />
          ))}
        </div>
      </div>
    </Wrapper>
  );
};
