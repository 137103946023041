import * as React from 'react';
import * as styles from './icon-card.module.scss';
import { Icon } from '../icon/icon';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

export interface IconCardItem {
  headline: string;
  description: string;
  icon: string;
}
interface IProps {
  className?: string;
  data: IconCardItem;
}
export const IconCard = ({ data, className = '' }: IProps) => (
  <div
    className={`${styles.card} ${className} d-flex flex-column align-items-center`}
  >
    <Icon name={data.icon} className={styles.iconWrapper} />
    <h3 className={styles.headline}>
      <FormattedMessage id={data.headline} />
    </h3>
    <p className={styles.description}>
      <FormattedMessage id={data.description} />
    </p>
  </div>
);
