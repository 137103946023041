import * as React from 'react';
import {
  ButtonBase,
  ButtonBaseProps,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    padding: '15px 25px',
    textTransform: 'uppercase',
    border: `1px solid ${theme.palette.common.white}`,
    color: theme.palette.common.white,
  },
}));

export const Button = ({
  children,
  className,
  onClick,
  ...props
}: ButtonBaseProps) => {
  const classes = useStyles();

  return (
    <ButtonBase
      {...props}
      classes={{
        root: classes.root,
      }}
      className={className}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
};
