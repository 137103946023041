import * as React from 'react';
import { Wrapper } from '../../../../components/wrapper/wrapper';
import { HighlightedHeadline } from '../../../../components/highlighted-headline/highlighted-headline';
import {
  IconCard,
  IconCardItem,
} from '../../../../components/icon-card/icon-card';
import { IconType } from '../../../../components/icon/icon';

export const WeOffer = () => {
  const cardsData: IconCardItem[] = [
    {
      icon: IconType.OFFSET_PRINTING,
      headline: 'home.we_offer.printing.headline',
      description: 'home.we_offer.printing.description',
    },
    {
      icon: IconType.FLEX_PRINTING,
      headline: 'home.we_offer.flexographic_printing.headline',
      description: 'home.we_offer.flexographic_printing.description',
    },
    {
      icon: IconType.DIAMOND,
      headline: 'home.we_offer.luxury_boxes.headline',
      description: 'home.we_offer.luxury_boxes.description',
    },
    {
      icon: IconType.PUNCHING,
      headline: 'home.we_offer.lamination.headline',
      description: 'home.we_offer.lamination.description',
    },
    {
      icon: IconType.STACKED_BOXES,
      headline: 'home.we_offer.palletizing.headline',
      description: 'home.we_offer.palletizing.description',
    },
    {
      icon: IconType.TRANSPORT,
      headline: 'home.we_offer.transport.headline',
      description: 'home.we_offer.transport.description',
    },
  ];

  return (
    <Wrapper>
      <div className="container">
        <HighlightedHeadline translation="home.we_offer.headline" />
        <div className="row">
          {cardsData.map((card, index) => (
            <IconCard key={index} data={card} className="col-12 col-md-4" />
          ))}
        </div>
      </div>
    </Wrapper>
  );
};
