import * as React from 'react';
import * as styles from './highlighted-headline.module.scss';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

interface IProps {
  translation: string;
}
export const HighlightedHeadline = ({ translation }: IProps) => {
  return (
    <h2 className={styles.headline}>
      <FormattedMessage id={translation} />
    </h2>
  );
};
