import * as React from 'react';
import { PageProps } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { MainTemplate } from '../templates/main/main';
import { SEO } from '../components/seo/seo';
import { WelcomeSection } from '../ui/pages/home/welcome-section/welcome-section';
import { Highlights } from '../ui/pages/home/highlights/highlights';
import { WeOffer } from '../ui/pages/home/we-offer/we-offer';
import { StayInTouch } from '../ui/pages/home/stay-in-touch/stay-in-touch';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

const IndexPage = ({ location }: PageProps) => {
  const intl = useIntl();
  const { siteTitle, siteTitleAlt, siteUrlWithTrailingSlash, social } =
    useSiteMetadata();

  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    name: siteTitle,
    alternateName: siteTitleAlt,
    url: siteUrlWithTrailingSlash,
    logo: `${siteUrlWithTrailingSlash}static/a5e9628c6b27f5c9b34c6911644247f0/e9b55/Logo-min.png`,
    sameAs: [siteUrlWithTrailingSlash, social.facebook, social.youtube],
  };

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({ id: 'home.seo_title' })}
        description={intl.formatMessage({ id: 'home.seo_description' })}
        schemaMarkup={schemaMarkup}
      />
      <WelcomeSection />
      <Highlights />
      <WeOffer />
      <StayInTouch />
    </MainTemplate>
  );
};

export default IndexPage;
